.container {
  width: 100%;
  padding: 20px;
}

.form-heading {
  text-align: center;
  padding-top: 50px;
  font-size: 2em;
}

.form-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 900px;
}

.form-class label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.form-class textarea,
.form-class input {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1em;
}

.answer-section,
.tags-section {
  margin-bottom: 30px;
}

.table {
  width: 100%;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
}

.footer {
  text-align: center;
  padding: 20px;
}

.footer-content {
  margin-top: 20px;
}

.python-tags {
  margin: 0 10px;
}

.footer-links a {
  margin: 0 10px;
  text-decoration: none;
}

