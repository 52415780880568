/* ProfileDropdown.css */
.profile-dropdown {
  position: relative;
  display: inline-block;
}

.profile-btn {
  background-color: white;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f9f9f9;
  min-width: 120px;
  border: 1px solid #ddd;
  z-index: 1;
}

.dropdown-content div {
  padding: 10px;
  cursor: pointer;
}

.dropdown-content div:hover {
  background-color: #f1f1f1;
}

