/* form {
  textn-bottom: 15pxn-bottom: 15px;;galign: left;
  padding-left: 10%;
  padding-right: 5%;
  font-size:"18px"; 
  width:"80%";
} */

.form-class {
  text-align: left;
  padding-left: 10%;
  padding-right: 5%;
  font-size:"18px"; 
  width:"80%";
}

.form-activity {
  width:3% !important;
}

.form-activity-correct {
  width:25% !important;
}

.notify-link {
color: blue !important;
text-decoration: underline !important; 
}

.logo {
  color: black;
}

.Aboutpage{
  text-align: left;
  font-size: 12px;
  padding-left: 10%;
}

.img-logo {
  display: flex;
  align-items: flex-start;
  width:30%;
}

.team-mems {
font-size: 18px !important;
}

.team-pro {
font-size: 16px !important;
margin-top: 0;
margin:0;
}


.privacy{
    padding-top:15px;
    font-size: 12px;
}

.submit-link-row{
    color: #0F0428;
    text-align: left;
}

button {
    display: block;
    margin-top: 20px; /* Optional for spacing */
}
label, input {
    display: block;  /* Ensure each element starts on a new line */
    width: 100%;     /* Optional: Makes the input fields take up full width */
}
.row{
    padding-top: 4%;
    padding-bottom: 3%;
    --bs-gutter-x: 0rem !important;
}

.Versionss {
  font-size: 10px;
}

a {
  color: #282c34 !important;
  text-decoration: none !important;
}

.container-2{
  background-color:  white;
  font-family: arial;
  height: auto;
  width:80%;
  padding: 3px 1px;
  margin:0 auto;
  border-radius:3px;

}

input{
   border-bottom: 1px solid #9b9b9b;
   font-size: 14px !important;
   width: 100%;
   height: 3rem;
   border: none;
   background-color: transparent;
   outline: none;
   margin: 0 0 15px 0;
   padding: 0;
   border-bottom: 1px solid #9b9b9b;
}

.container-footer{
  font-family: arial;
  display: flex;
  width: 100%;
  justify-content: center;
  background-color:  white;
  border-radius:3px;   
}


.social-media-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.social-media-icons a {
  color: #000; /* Change to your desired color */
  font-size: 24px; /* Adjust the size of the icons */
  transition: color 0.3s;
}

.social-media-icons a:hover {
  color: #007bff; /* Change to your desired hover color */
}

.notify-form label {
  display: block;
  margin-bottom: 10px; /* Adds space between the label and input */
}

.notify-form input,
.notify-form select {
  width: 100%; /* Ensures inputs take full width */
  padding: 8px;
  margin-bottom: 15px; /* Adds space between inputs */
}

.notify-form button {
  margin-top: 10px; /* Adds space between the buttons and the form fields */
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.notify-form button:hover {
  background-color: #0056b3;
}

.notify-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.notify-form {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
  max-width: 400px;
}
