.App {
  text-align:center;
}


.logo {
  color: black;
}

.sub-logo {
  color:rgb(145, 145, 145);
}


.tagline {
font-size: 1rem;
/* text-align:left; */
font-weight: 300;
width:70%;
}

strong{
font-weight: bold !important;
}

img{
  width:20% !important;

}

.img-logo {
  display: flex;
  align-items: flex-start;
  width:60%;
}


.container{
  font-family: Roboto, sans-serif;
  text-align: justify;
  height: auto;
  width: 50%;
  color:rgb(8, 6, 6);
  padding: 3px 1px;
  background-color:  #f1f1f1;
  outline: 1px rgb(0, 0, 0);
  margin:0 auto;
  border-radius:3px;
  box-shadow: rgb(0, 0, 0);
}

/* .container-code{
  font-family: Roboto, sans-serif;
  text-align: left;
  height: 20%;
  width: 60%;
  color:rgb(8, 6, 6);
  padding: 3px 1px;
  outline: 1px rgb(0, 0, 0);
  margin:0 auto;
  border-radius:3px;
  box-shadow: rgb(0, 0, 0);
}
*/

.container-code{
  font-family: Roboto, sans-serif;
  text-align: left;
  height: 20%;
  width: 60%;
  font-size: 16px;
  /* color:rgb(8, 6, 6); */
  color: darkslategrey;
  padding: 3px 1px;
  outline: 1px rgb(0, 0, 0);
  margin:0 auto;
  border-radius:3px;
  background-color: #e7e6e6;
  box-shadow: rgb(0, 0, 0);
}





.Versionss {
  font-size: 10px;
}

a {
  color: #282c34 !important;
  text-decoration: none !important;
}

/* button {
  height: 30px;
  width: 100px;
} */

ul {
  list-style:none;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media screen and (max-width: 800px) {
  .container-code {
    width: 85%; /* The width is 100%, when the viewport is 800px or smaller */
  }
} 

@media screen and (max-width: 1000px) {
  .container {
    width: 98% !important; /* The width is 100%, when the viewport is 800px or smaller */
  }
}

@media screen and (max-width: 1000px) {
  button {
    font-size: 23px !important;
  }
}

.App-header {
  /* background-color: #282c34;*/
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Main-class{
  /* padding: 3px 1px; */
  background-color:  white;
  padding-top: 2%;
  margin:0 auto;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2%;
}

@media screen and (max-width: 1000px) {
  .Main-class {
    padding-bottom: 4%;
    padding-top: 4%;
  }
}


/* footer {
  padding: 25px 0;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  border-top: 1px solid #efefef;
}
 */


footer {
  background-color:  white;
  padding: 25px 0;
  justify-content: center;
  box-sizing: border-box;
  position: absolute;
  width:80%;
  border-top: 1px solid #efefef;
}


.container-footer{
  font-family: arial;
  display: flex;
  width: 100%;
  justify-content: center;
  background-color:  rgb(253, 253, 253);
  border-radius:3px; 
  
}

.footer-about{
  color: #806d6d !important;
}


.container-2{
  background-color:  white;
  font-family: arial;
  height: auto; 
  width:80%;
  padding: 3px 1px;
  margin:0 auto;
  border-radius:3px; 
  
}

.Next-question {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height:30px;
  text-align:center;
  background-color: #0dbff0;
  color: rgb(0, 0, 0);
  border: none;
}

@media screen and (max-width: 1000px) {
  .Next-question {
    height:35px !important;
  }
}

/* App.css or your main stylesheet */



header {
  border-bottom: 1px solid #efefef;
}

header {
  display: grid;
  grid-template-columns: auto auto;
  align-items: left;
  display: flex;
  align-items:center;
  /* justify-content: space-between; */
  padding-top: 20px;
  padding-bottom: 10px;
}

.logo
{
  font-weight: 700;
  font-size: 1.375rem;
  text-decoration: none;
}

li {
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 7px;
  width:98%;
  color: rgb(255, 255, 255);
}

button {
  width:100%;
  height:40%;
  color: #777a77;
  background-color: #ffffff;
  word-wrap: break-word;
}

/* li.correct {
  background-color: rgb(87, 209, 87);
}
li.selected:not(correct) {
  background-color: rgb(228, 100, 100);
} */



.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  
}



.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

}

.Auth-form {
  width: 420px;
  box-shadow: rgba(197, 97, 97, 0.16) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 5px;
  background-color: white;
}


.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}


label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
  display: inline !important;
}



.author-tags-class {
  text-align: left;
  color:dimgray;
  font-size: 14px;
}

.python-tags{
  color: #707070;
  border: "px solid black";
  font-size: 14px;
}
.social-media-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.social-media-icons a {
  color: #000; /* Change to your desired color */
  font-size: 24px; /* Adjust the size of the icons */
  transition: color 0.3s;
}

.social-media-icons a:hover {
  color: #007bff; /* Change to your desired hover color */
}

.link-spacing {
  margin-right: 10px; /* Adjust the spacing as needed */
}

.menu {
  display: flex; /* Arrange items in a row */
  gap: 20px; /* Space between menu items */
  /*padding: 10px; /* Padding around the menu */
  /*border-radius: 5px; /* Rounded corners for the menu */
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for the menu */
}

.menu-item {
  padding: 4px 4px; /* Padding inside each menu item */
  text-decoration: none; /* Remove underline from links */
  color: #333; /* Text color */
  /*border: 1px solid #ddd; /* Border for menu items */
}

.menu-item:hover {
  color: #ffffff; /* Text color on hover */
  background-color: #d3d3d3;
}

.menu-item:active {
  color: #ffffff; /* Text color when active */
  background-color: #0056b3;
}
.menu-item.active {
  background-color: #d3d3d3; /* Background color for active menu item */
  color: #333; /* Text color for active menu item */
}
